import React from 'react';
import Layout from '../../layouts/contact/contact';
import Banner from '../../page-components/contact/banner/banner';

const Contact = () => {
  return (
    <Layout>
      <Banner
        who={'Us About the Innovation Plan'}
        messages={[
          'Understand user behavior in conversational interfaces',
          'Increase user engagement',
          'Track Key Performance Indicators',
          'Benchmark and compare chatbots and voice skills',
          'Build the case for business unit adoption',
        ]}
      />
    </Layout>
  );
};

export default Contact;
